import { useEffect, useState } from "react";
import { InferGetServerSidePropsType, GetServerSideProps } from "next";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import { loginAPI } from "store/slices/loginSlice";
import { UserSelector } from "store/api/types";
import Layout from "../../components/Layout";
import type { AppDispatch } from "store";

// eslint-disable-next-line @typescript-eslint/require-await
export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, query } = context;
  const cookie = req.cookies.auth_token;
  const returnUrl = query.returnUrl as string;

  if (!cookie) {
    return {
      props: { isLoggedIn: false },
    };
  }

  return {
    redirect: {
      destination: returnUrl ?? "/pursuits/dashboard",
      permanent: false,
    },
  };
};

// Use the cookie in your component
const Login = ({
  isLoggedIn,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      const returnUrl = router.query.returnUrl as string;
      router.push(returnUrl ?? "/pursuits/dashboard").catch((e) => {
        console.error(e);
      });
    } else {
      // finish logging out
      localStorage.removeItem("token");
    }
  }, [isLoggedIn, router]);

  const onLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && password) {
      const loginThunk = dispatch(loginAPI({ email, password })).unwrap();
      loginThunk
        .then(() => {
          const returnUrl = router.query.returnUrl as string;
          router.push(returnUrl ?? "/pursuits/dashboard").catch((err) => {
            console.error(err);
          });
        })
        .catch(({ error }: { error: string }) => {
          setLoginError(error);
        });
    }
  };

  return (
    <Layout title="Sign in to Caltana" titleComponent="h2">
      <Box component="form" onSubmit={onLoginSubmit} noValidate>
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <TextField
            autoFocus
            autoComplete="email"
            disabled={false}
            error={loginError !== ""}
            fullWidth
            helperText={loginError}
            id="email"
            label="Email Address"
            aria-label="Email Address"
            inputProps={{ "data-testid": "login-email" }}
            className="login-email"
            margin="normal"
            name="email"
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="password">Password*</InputLabel>
          <OutlinedInput
            autoComplete="current-password"
            disabled={false}
            error={loginError !== ""}
            required
            fullWidth
            name="password"
            className="login-password"
            inputProps={{ "data-testid": "login-password" }}
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link
            href="#"
            onClick={() => {
              router.replace("/auth/reset").catch((e) => {
                console.error(e);
              });
            }}
          >
            Forgot your password?
          </Link>
          <Button
            className="login-submit"
            color="primary"
            disabled={false}
            type="submit"
            variant="contained"
            data-testid="login-submit"
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};
Login.isUnprotected = true;
export default Login;
